
.image-container{
    height: 18rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-container{
    height: 30rem;
}
