*{
  /* outline: 1px solid red;  */
  font-family: 'Raleway', sans-serif;
}

p {
  font-size: 1.25em;
}

h3{
  font-weight: 800;
}